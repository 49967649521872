<template>
  <div class="showDetails">
    <div class="btns">
      <el-button
        type="primary"
        :disabled="!operateId"
        size="small"
        @click="showDeails"
        v-if="$store.state.menu.nowMenuList.indexOf('查看方案') >= 0"
        >查看方案</el-button
      >
    </div>
    <el-dialog
      title="查看方案"
      :visible.sync="driverdialogVisible"
      v-if="driverdialogVisible"
      width="70%"
    >
      <div class="details">
        <div class="left">
          <el-table :data="tableData" height="65vh">
            <el-table-column
              prop="stationNo"
              label="班次"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="teamName"
              label="班组"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="vehicleNo"
              label="车辆"
              width="110"
            ></el-table-column>
            <el-table-column
              prop="credentialsNo"
              label="驾驶员"
              width="110"
            ></el-table-column>
          </el-table>
        </div>
        <div class="form search-area">
          <el-form
            :model="setForm"
            status-icon
            label-position="left"
            ref="setform"
            label-width="110px"
            disabled
            class="dialog-form form-search updateform"
          >
            <template>
              <div class="title">方案执行周期</div>
              <div class="inner-item">
                <el-form-item label="执行时间:" prop="startTime">
                  <el-date-picker
                    v-model="setForm.startTime"
                    placeholder="选择日期"
                    type="date"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:" prop="endTime">
                  <el-date-picker
                    v-model="setForm.endTime"
                    placeholder="选择日期"
                    type="date"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </template>
            <template>
              <div class="inner-item">
                <div class="title">主副场周期互换</div>
                <el-form-item label-width="0" prop="roundType">
                  <el-radio-group v-model="setForm.roundType">
                    <el-radio :label="3">不轮换</el-radio>
                    <el-radio :label="0">固定时间轮换</el-radio>
                    <el-radio :label="1">周期轮换</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="inner-item" v-if="setForm.roundType == 0">
                <el-form-item label="互换日期(每月):" prop="start">
                  <el-input-number
                    controls-position="right"
                    v-model="setForm.start"
                    :min="1"
                    :max="31"
                    label="输入互换日期"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="互换日期(每月):" prop="end">
                  <el-input-number
                    controls-position="right"
                    v-model="setForm.end"
                    :min="1"
                    :max="31"
                    label="输入互换日期"
                  ></el-input-number>
                </el-form-item>
              </div>
              <div class="inner-item" v-if="setForm.roundType == 1">
                <el-form-item label="周期(天):" prop="day">
                  <el-input-number
                    controls-position="right"
                    v-model="setForm.day"
                    :min="1"
                    :max="31"
                    label="输入周期"
                  ></el-input-number>
                </el-form-item>
              </div>
            </template>
            <!-- 轮班方式 -->
            <template>
              <div class="title">轮班方式</div>
              <div v-for="(item, index) in classTakeTurnsData" :key="index">
                <div class="truns-item">
                  <p class="p-title">{{ item.teamName }}</p>
                  <div class="inner-item">
                    <el-form-item label="驾驶员轮休顺序:">
                      <el-select v-model="item.workerShifts">
                        <el-option
                          v-for="item in driverShiftsOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="车辆轮班顺序:">
                      <el-select v-model="item.workShifts">
                        <el-option
                          v-for="item in shiftsOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <el-form-item label="非机动驾驶员:">
                    <template>
                      <div class="drivers drivers-full">
                        <div
                          class="drivers-name"
                          v-for="(el, id) in item.binds"
                          :key="id"
                        >
                          {{ el.driverName }}
                        </div>
                      </div>
                    </template>
                  </el-form-item>
                  <el-form-item label="机动驾驶员:">
                    <div class="full-flex">
                      <!-- <el-select
                        v-model="item.unbinds"
                        filterable
                        multiple
                        collapse-tags
                        placeholder="请选择司机"
                        value-key="credentialsNo"
                      >
                        <el-option
                          v-for="element in sourceDrivers"
                          :key="element.credentialsNo"
                          :label="element.driverName"
                          :value="element"
                        ></el-option>
                      </el-select> -->
                      <div class="full-inner">
                        <div
                          class="drivers-name"
                          v-for="(ele, idn) in item.unbinds"
                          :key="idn"
                        >
                          {{ ele.driverName }}
                        </div>
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </template>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { queryCurrentOperateAPI } from "@/api/lib/bus-api.js";
export default {
  props: {
    operateId: {
      type: Number,
      default: 21
    },
    companyId: {
      type: Number,
      default: null
    },
    sourceDrivers: {
      type: Array,
      default: () => []
    },
    carOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      driverdialogVisible: false,
      tableData: [],
      setForm: {
        startTime: new Date().getTime() + 3600 * 1000 * 24,
        endTime: null,
        roundType: null,
      },
      classTakeTurnsData: [],//轮班方式数据源
      driverShiftsOptions: [
        {
          value: 0,
          label: '顺时针'
        },
        {
          value: 1,
          label: '逆时针'
        },
      ],
      shiftsOptions: [
        {
          value: 0,
          label: '顺时针'
        },
        {
          value: 1,
          label: '逆时针'
        },
        {
          value: 2,
          label: '固定'
        },
      ],
    }
  },
  mounted () {

  },
  methods: {
    showDeails () {

      this.getBisicData();
    },
    getBisicData () {
      let tableSingleData = [];//表格数据
      let formSingleData = [];//表单数据
      queryCurrentOperateAPI({ companyId: this.companyId }).then(res => {
        if (res.code === 1000) {
          const { startTime, schedulingTeams, endTime, banCiRound, roundType, banCiRoundNum, roundDates } = res.data;
          //拼装表格数据
          schedulingTeams.map((item, index) => {
            formSingleData[index] = Object.assign({}, {
              teamName: item.teamName,
              workShifts: item.workShifts,
              workerShifts: item.workerShifts,
              binds: [],
              unbinds: []
            });
            //item是分组，分组里面是每一个班次信息
            item.binds.map(ele => {
              //表单数据拼装//驾驶员类型 0：非机动 1：机动
              if (ele.driverType == 0) {
                tableSingleData.push({
                  stationNo: ele.stationNo,
                  teamName: item.teamName,
                  vehicleNo: ele.vehicleNo ? (this.carOptions.find(i => i.vehicleNo == ele.vehicleNo) || { cph: "" }).cph : '',
                  credentialsNo: ele.credentialsNo ? (this.sourceDrivers.find(i => i.credentialsNo == ele.credentialsNo) || { driverName: "" }).driverName : ""
                });
                formSingleData[index].binds.push({
                  vehicleNo: ele.vehicleNo,
                  stationNo: ele.banciNo,
                  credentialsNo: ele.credentialsNo,
                  driverName: ele.credentialsNo ? (this.sourceDrivers.find(i => i.credentialsNo == ele.credentialsNo) || { driverName: "" }).driverName : "",
                  driverType: 0,//驾驶员类型 0：非机动 1：机动
                })
              } else {
                formSingleData[index].unbinds.push({
                  vehicleNo: ele.vehicleNo,
                  stationNo: ele.banciNo,
                  credentialsNo: ele.credentialsNo,
                  driverName: ele.credentialsNo ? (this.sourceDrivers.find(i => i.credentialsNo == ele.credentialsNo) || { driverName: "" }).driverName : "",
                  driverType: 1,//驾驶员类型 0：非机动 1：机动
                })
              }
            })

          });
          this.classTakeTurnsData = [...formSingleData];
          this.tableData = [...tableSingleData];
          //拼装右侧表单数据          
          this.setForm = {
            startTime,
            endTime,
            roundType,
            day: banCiRoundNum,
            start: roundDates[0],
          }
          roundDates[1] != null && (this.setForm.end = roundDates[1]);//否则不赋值
          this.driverdialogVisible = true;
        } else {
          this.$message.warning(res.msg);
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.showDetails {
  margin-right: 16px;
  .details {
    display: flex;
    height: 66vh;
    .search-area {
      height: 66vh;
      overflow-y: auto;
    }
    .title {
      color: #000000;
      // font-size: 18px;
      margin: 16px 0;
      padding-bottom: 16px;
    }
    .inner-item {
      display: flex;
      align-items: center;
    }
    .drivers {
      width: 200px;
      display: flex;
      flex-wrap: wrap;
      .drivers-name {
        border: 1px solid #ef8900;
        border-radius: 4px;
        color: #ef8900;
        padding: 0 8px;
        margin-right: 16px;
      }
    }
    .full-flex {
      width: 100%;
      display: flex;
      align-items: flex-start;
      .full-inner {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        // margin-left: 16px;
        .drivers-name {
          border: 1px solid #13d4c8;
          border-radius: 4px;
          color: #13d4c8;
          padding: 0 8px;
          // margin: 0 16px 16px;
          margin-right: 16px;
          .its_quxiao {
            cursor: pointer;
          }
        }
      }
    }
    .drivers-full {
      width: 100%;
    }
    .truns-item {
      padding: 16px 32px 0;
      background: rgba(220, 222, 223, 0.1);
      margin-bottom: 16px;
      .p-title {
        color: #333;
      }
    }
  }
}
::v-deep .el-form .el-form-item {
  margin-left: 30px;
  .el-form-item__content {
    height: inherit !important;
  }
}
</style>