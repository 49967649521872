<template>
  <div class="project-comp">
    <div class="btns">
      <el-button
        type="primary"
        :disabled="!operateId"
        size="small"
        @click="carryOut"
        v-if="$store.state.menu.nowMenuList.indexOf('执行此方案') >= 0"
        >执行此方案</el-button
      >
    </div>
    <!-- 执行方案 -->
    <el-dialog
      title="方案设置"
      :visible.sync="driverdialogVisible"
      v-if="driverdialogVisible"
      width="50%"
    >
      <div class="form search-area">
        <el-form
          :model="setForm"
          status-icon
          :rules="setrules"
          label-position="left"
          ref="setform"
          label-width="110px"
          class="dialog-form form-search updateform"
        >
          <template>
            <div class="title">方案执行周期</div>
            <div class="inner-item">
              <el-form-item label="执行时间:" prop="startTime">
                <el-date-picker
                  v-model="setForm.startTime"
                  placeholder="选择日期"
                  type="date"
                  :picker-options="pickerDateOptions"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间:" prop="endTime">
                <el-date-picker
                  v-model="setForm.endTime"
                  placeholder="选择日期"
                  type="date"
                  :picker-options="pickerDateOptions"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </div>
          </template>
          <template>
            <div class="inner-item">
              <div class="title">主副场周期互换</div>
              <el-form-item label-width="0" prop="roundType">
                <el-radio-group v-model="setForm.roundType">
                  <el-radio :label="3">不轮换</el-radio>
                  <el-radio :label="0">固定时间轮换</el-radio>
                  <el-radio :label="1">周期轮换</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="inner-item" v-if="setForm.roundType == 0">
              <el-form-item label="互换日期(每月):" prop="start">
                <el-input-number
                  controls-position="right"
                  v-model="setForm.start"
                  :min="1"
                  :max="31"
                  label="输入互换日期"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="互换日期(每月):" prop="end">
                <el-input-number
                  controls-position="right"
                  v-model="setForm.end"
                  :min="1"
                  :max="31"
                  label="输入互换日期"
                ></el-input-number>
              </el-form-item>
            </div>
            <div class="inner-item" v-if="setForm.roundType == 1">
              <el-form-item label="周期(天):" prop="day">
                <el-input-number
                  controls-position="right"
                  v-model="setForm.day"
                  :min="1"
                  :max="31"
                  label="输入周期"
                ></el-input-number>
              </el-form-item>
            </div>
          </template>
          <!-- 轮班方式 -->
          <template>
            <div class="title">轮班方式</div>
            <div v-for="(item, index) in classTakeTurnsData" :key="index">
              <div class="truns-item">
                <p class="p-title">{{ item.teamName }}</p>
                <div class="inner-item">
                  <el-form-item label="驾驶员轮休顺序:">
                    <el-select v-model="item.workerShifts">
                      <el-option
                        v-for="item in driverShiftsOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="车辆轮班顺序:">
                    <el-select v-model="item.workShifts">
                      <el-option
                        v-for="item in shiftsOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-form-item label="非机动驾驶员:">
                  <template>
                    <div class="drivers drivers-full">
                      <div
                        class="drivers-name"
                        v-for="(el, id) in item.binds"
                        :key="id"
                      >
                        {{ el.driverName }}
                      </div>
                    </div>
                  </template>
                </el-form-item>
                <el-form-item label="机动驾驶员:">
                  <div class="full-flex">
                    <el-select
                      v-model="item.unbinds"
                      filterable
                      multiple
                      collapse-tags
                      placeholder="请选择司机"
                      @change="handelChange($event, item, index)"
                      value-key="credentialsNo"
                    >
                      <el-option
                        v-for="element in sourceDrivers"
                        :key="element.credentialsNo"
                        :label="element.driverName"
                        :value="element"
                        :disabled="isHas(element.credentialsNo, index)"
                      ></el-option>
                    </el-select>
                    <div class="full-inner">
                      <div
                        class="drivers-name"
                        v-for="(ele, idn) in item.unbinds"
                        :key="idn"
                      >
                        {{ ele.driverName }}
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>
            </div>
          </template>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          size="small"
          @click="driverdialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="loading"
          size="small"
          @click="update"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getOperateList,
  queryDriverByName,
  addSchedulingV2API
} from "@/api/lib/bus-api.js";
import {
  formatDay
} from '@/common/utils/index';
export default {
  props: {
    operateId: {
      type: Number,
      default: 21
    },
    companyId: {
      type: Number,
      default: null
    },
    classList: {
      type: Array,
      default: () => []
    },
    sourceDrivers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      driverdialogVisible: false,
      driverShiftsOptions: [
        {
          value: 0,
          label: '顺时针'
        },
        {
          value: 1,
          label: '逆时针'
        },
      ],
      shiftsOptions: [
        {
          value: 0,
          label: '顺时针'
        },
        {
          value: 1,
          label: '逆时针'
        },
        {
          value: 2,
          label: '固定'
        },
      ],
      setForm: {
        startTime: new Date().getTime() + 3600 * 1000 * 24,
        endTime: null,
        // start: "",
        // end: "",
        // day: "",
        roundType: 3,//0:指定日期，1：指定周期
        // banCiRound: false,//是否轮换
        //班组周期性轮班指定的日期 roundDates
      },
      setrules: {
        startTime: [{ required: true, message: "不能为空", trigger: "change" }],
        type: [{ required: true, message: "不能为空", trigger: "change" }],
        start: [{ required: true, message: "不能为空", trigger: "blur" }],
        // end: [{ required: true, message: "不能为空", trigger: "blur" }],
        day: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      pickerDateOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now();
        },
      },
      activeName: '',
      detailData: [],//方案数据
      classTakeTurnsData: [],//轮班方式数据源
    }
  },
  mounted () {
    this.activeName = this.classList[0]?.teamName;
  },
  methods: {
    //判断是否已经被选机动和非机动
    isHas (value, exclude) {
      //过滤出线路剩余可选非机动驾驶员：原始数据 - 机动驾驶员 -已经选择非机动驾驶员
      //当前项的机动驾驶员不作禁用
      let num = 0;
      this.classTakeTurnsData.map((item, index) => {
        item.binds.map(ele => {
          if (ele.credentialsNo == value) num++;
        })
        if (index != exclude) {
          item.unbinds.map(ele => {
            if (ele.credentialsNo == value) num++;
          })
        }
      })
      return num > 0
    },
    handelChange (e, index) {
      console.log("++++++", e, index);
    },
    carryOut () {
      let flag = false;//所有的班次都必须分组、配置驾驶员和车辆才能执行方案
      getOperateList({ operateId: this.operateId }).then(res => {
        if (res.code === 1000) {
          let tableData = res.data && res.data.length > 0
            ? res.data
            : [];
          //组装轮班方式---从列表中拿取用到的组类（循环的方式合并相同班组数据
          let tempArr = [];//缓存班组名称数组
          let resultArr = []; //重组数据
          tableData.forEach((ele, i) => {
            if (!ele.teamName || !ele.credentialsNo || !ele.vehicleNo) flag = true;
            if (tempArr.indexOf(ele.teamName) === -1 && ele.teamName) {
              tempArr.push(ele.teamName);
              resultArr.push({
                teamName: ele.teamName,
                workShifts: 0,//车辆轮班顺序 0-顺时针 1-逆时针 2-固定
                workerShifts: 0,//驾驶员倒班模式 0-顺时针 1-逆时针 2-固定
                binds: [{
                  vehicleNo: ele.vehicleNo,
                  stationNo: ele.banciNo,
                  credentialsNo: ele.credentialsNo,
                  driverName: ele.driverName,
                  driverType: 0,//驾驶员类型 0：非机动 1：机动
                }],
                unbinds: [],//装机动驾驶员字段预留
              });
            } else {
              resultArr.forEach((dom, k) => {
                if (dom.teamName == ele.teamName) {
                  dom.binds.push({
                    vehicleNo: ele.vehicleNo,
                    stationNo: ele.banciNo,
                    credentialsNo: ele.credentialsNo,
                    driverName: ele.driverName,
                    driverType: 0,//驾驶员类型 0：非机动 1：机动
                  });
                }
              })
            }
          })
          this.classTakeTurnsData = [...resultArr];
          this.detailData = [...tableData];
          this.$nextTick(() => {
            if (flag) this.$message.warning("请先完善好运营方案~");
            this.loading = false;
            this.driverdialogVisible = !flag;
          })

        } else {
          this.$message.warning(res.msg);
        }
      }).catch(err => {
        this.$message.error(err.msg)
      })
    },

    update () {
      this.$refs.setform.validate((validate) => {
        if (validate) {
          const { startTime, endTime, start, end, day, roundType } = this.setForm;
          if (new Date((endTime + ' 23:59:59')).getTime() < startTime) {
            this.$message.warning('结束时间不能小于执行时间');
            return
          }
          this.loading = true;
          //机动驾驶员还需要重组放进binds
          const data = {
            startTime: formatDay(startTime),
            endTime: endTime ? formatDay(endTime) : endTime,
            operateId: this.operateId,
            lineId: this.companyId,
            banCiRound: roundType != 3,//主副场是否周期性互换
            roundType: roundType,//
            banCiRoundNum: day,//班组周期性轮班天数
            roundDates: [start, end],//班组周期性轮班指定的日期
            schedulingTeams: [],
          }
          let oldData = JSON.parse(JSON.stringify(this.classTakeTurnsData));
          oldData.map(item => {
            item.unbinds.map(ele => {
              item.binds.push({
                credentialsNo: ele.credentialsNo,
                driverType: 1,//非机动
              })
            })
          });
          data.schedulingTeams = oldData;
          this.$nextTick(() => {
            addSchedulingV2API(data).then(res => {
              if (res.code === 1000) {
                this.$message.success(res.msg);
                this.driverdialogVisible = false;
              } else {
                this.$message.warning(res.msg);
              }
              this.loading = false;
            }).catch(() => {
              this.loading = false;
            })
          })
        }
      })
    }
  },

}
</script>
<style lang="scss" scoped>
.project-comp {
  .title {
    color: #000000;
    font-size: 18px;
    margin: 16px 0;
    padding-bottom: 16px;
  }
  .inner-item {
    display: flex;
    align-items: center;
  }
  .drivers {
    width: 200px;
    display: flex;
    flex-wrap: wrap;
    .drivers-name {
      border: 1px solid #ef8900;
      border-radius: 4px;
      color: #ef8900;
      padding: 0 8px;
      margin-right: 16px;
    }
  }
  .full-flex {
    width: 100%;
    display: flex;
    align-items: flex-start;
    .full-inner {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-left: 16px;
      .drivers-name {
        border: 1px solid #13d4c8;
        border-radius: 4px;
        color: #13d4c8;
        padding: 0 8px;
        margin: 0 16px 16px;
        .its_quxiao {
          cursor: pointer;
        }
      }
    }
  }
  .drivers-full {
    width: 100%;
  }
  .truns-item {
    padding: 16px 32px 0;
    background: rgba(220, 222, 223, 0.1);
    margin-bottom: 16px;
    .p-title {
      color: #333;
    }
  }
}
::v-deep .el-form .el-form-item {
  margin-left: 30px;
  .el-form-item__content {
    height: inherit !important;
  }
}
</style>